import {Color3, StandardMaterial, Vector3, PhysicsImpostor, TransformNode} from 'babylonjs';
import Obstacle from '../types/Obstacle';
import {Game} from '../index'

export interface BackboardFile {
    src:string, 
    fromDate?:string, 
    toDate?:string
}
export const goalies:string[] = [
    "Pinball-goalie-1.png",
    "Pinball-goalie-2.png",
    "Pinball-goalie-3.png",
    "Pinball-goalie-4.png",
    "Pinball-goalie-5.png"
];
export const highlightColor:Color3 = Color3.FromHexString("#0094da");
export const musicFiles:string[] = [
    'publiek-stadion'
];
export const backboardFiles:BackboardFile[] = [
    {
        src: "./assets/3d-models/Images/backboard-01.jpg?nocache=1724415862597"
    },
    {
        src: "./assets/3d-models/Images/backboard-02.jpg?nocache=1724415862597"
    }
];



export const obstacles:Obstacle[] = [
    {
        name: "target",
        fileName: "Target.obj",
        position: new Vector3(-13, 0, -11),
        rotationY: 0.2 * Math.PI,
        scale: 2,
        score: 20,
        processObject: (meshes,  scene, obstacle, createShadowPlane) => {
            console.log(scene);
            try {
                let object = meshes[0];
                for(let mesh of meshes) {
                    if(mesh != object) {
                        mesh.setParent(object)
                    }
                }
    
                object.name = obstacle.name;
                if(obstacle.scale) {
                    object.scaling.x = object.scaling.y = object.scaling.z = obstacle.scale;
                }
                
                object.position.copyFrom(obstacle.position);
                object.position.y = 300;
                
                object.rotation.y = obstacle.rotationY;
                
                object.physicsImpostor = new PhysicsImpostor(object, PhysicsImpostor.BoxImpostor, {mass:0, restitution: Game.OBSTACLE_RESTITUTION, friction:Game.OBSTACLE_FRICTION}, scene);
    
                let shadowplane = createShadowPlane(`${obstacle.name}_shadow`, 14, 9);
                shadowplane.position.copyFrom(obstacle.position);
                shadowplane.position.y = 0.09999;
                shadowplane.rotation.y = obstacle.rotationY;
                shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
                shadowplane.visibility = 0;
    
                return {
                    name:obstacle.name,
                    lastTrigger:0,
                    cooldown:1000,
                    isGoal:false,
                    points: obstacle.score,
                    renderedObject: object,
                    collisionObject: object,
                    fakeShadows: [shadowplane],
                    position: obstacle.position,
                    zone: obstacle.zone
                }
            } catch (e) {
                console.error(`unable to process ${obstacle.name}`, e);
            }
            return;
        },
        zone: "topRight"
    },
    {
        name: "helmet",
        fileName: "helmet.obj",
        rotationY: 1.25 * Math.PI,
        position: new Vector3(15, 0, 28),
        zone: 'bottomLeft',
        score:10,
        scale: 16,
        processObject: (meshes,  scene, obstacle, createShadowPlane) => {
            try {
            	let object = meshes[0];
            
                for(let mesh of meshes) {
                    if(mesh != object) {
                        mesh.setParent(object);
                    }
                }

                let rotation = obstacle.rotationY;
                object.name = obstacle.name;
                object.scaling.x = object.scaling.y = object.scaling.z = obstacle.scale;
                object.material.backFaceCulling = false;
                object.position.copyFrom(obstacle.position);
                	object.position.y = 300;
                	object.rotation.y = rotation
                	object.physicsImpostor = new PhysicsImpostor(object, PhysicsImpostor.CylinderImpostor, {mass:0, restitution: Game.OBSTACLE_RESTITUTION, friction:Game.OBSTACLE_FRICTION}, scene);
                	// object.receiveShadows = true;

                	let shadowplane = createShadowPlane('object_shadow', 20.25, 24.75, true);
                	shadowplane.position.copyFrom(obstacle.position);
                	shadowplane.position.y = 0.09999;
                	shadowplane.rotation.y = obstacle.rotationY;
                	shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
                	shadowplane.visibility = 0;

                return {
                    name:obstacle.name,
                    lastTrigger:0,
                    cooldown:1000,
                    isGoal:false,
                    points: obstacle.score,
                    renderedObject: object,
                    collisionObject: object,
                    fakeShadows: [shadowplane],
                    position: obstacle.position,
                    zone: obstacle.zone
                }
            } catch (e) {
                console.error(`unable to process ${obstacle.name}`, e);
            }
            return;
        }
    },
    {
        name:"shoes",
        position: new Vector3(-15, 1.9, 28),
        rotationY: 0.6*Math.PI,
        zone:'bottomRight',
        fileName:"Schoen.obj",
        score:10,
        processObject: (meshes,  scene, obstacle, createShadowPlane) => {
            try {
                let shoes = new TransformNode('shoes');
                shoes.name = 'shoes';
                meshes[0].setParent(shoes);
                meshes[1].setParent(shoes);
                meshes[0].material.backFaceCulling = false;
                meshes[1].material.backFaceCulling = false;
                shoes.scaling.x = shoes.scaling.y = shoes.scaling.z = 1.2 * 2.5;
                shoes.scaling.x *= -1;
                
                shoes.position.copyFrom(obstacle.position);
                shoes.position.y = 300;
                shoes.rotation.y = 0;
    
                let shoesDummy = Game.createObstacle(scene, obstacle.position.clone(), 8, 10, 9);
                shoesDummy.position.x += 0.2;
                shoesDummy.position.z += 0.7; 
                shoesDummy.rotation.y = 0.6*Math.PI;
    
                
                let shadowplane = createShadowPlane('shoes_shadow', 10, 24.5, true);
                shadowplane.position.copyFrom(obstacle.position);
                shadowplane.position.x = -17;
                shadowplane.position.z = 28;
                shadowplane.rotation.y = 1 * Math.PI;
                shadowplane.position.y = 0.09999;
                shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
                shadowplane.visibility = 0;
    
                let shadowplane2 = createShadowPlane('shoes2_shadow', 10, 22.5, true);
                shadowplane2.position.copyFrom(obstacle.position);
                shadowplane2.position.x = -14.25
                shadowplane2.position.z = 27;
                shadowplane2.rotation.y = 0.35 * Math.PI;
                shadowplane2.position.y = 0.09999;
                shadowplane2.scaling = new Vector3(0.1, 0.1, 0.1);
                shadowplane2.visibility = 0;

                return {
                    name:obstacle.name,
                    lastTrigger:0,
                    cooldown:1000,
                    isGoal:false,
                    points: obstacle.score,
                    renderedObject: shoes,
                    collisionObject: shoesDummy,
                    fakeShadows: [shadowplane, shadowplane2],
                    position: obstacle.position,
                    zone: obstacle.zone
                }
            } catch(e) {
                console.error(`unable to process ${obstacle.name}`, e);
            }
            return;
        }
    },
    {
        name: "trophy",
        fileName: "Beker.obj",
        position: new Vector3(15, 0, -11),
        rotationY: 1.8 * Math.PI,
        scale: 1.2 * 1.95,
        score:20,
        zone:"topLeft",
        processObject: (meshes,  scene, obstacle, createShadowPlane) => {
            try {
                let object = meshes[0];
            
                for(let mesh of meshes) {
                    if(mesh != object) {
                        mesh.setParent(object);
                    }
                }

                let rotation = obstacle.rotationY;
                object.name = obstacle.name;
                object.scaling.x = object.scaling.y = object.scaling.z = obstacle.scale;
                object.material.backFaceCulling = false;
                object.position.copyFrom(obstacle.position);
                    object.position.y = 300;
                    object.rotation.y = rotation
                    object.physicsImpostor = new PhysicsImpostor(object, PhysicsImpostor.CylinderImpostor, {mass:0, restitution: Game.OBSTACLE_RESTITUTION, friction:Game.OBSTACLE_FRICTION}, scene);
                    object.receiveShadows = true;

                    let shadowplane = createShadowPlane(`${obstacle.name}_shadow`, 20.25, 24.75, true);
                    shadowplane.position.copyFrom(obstacle.position);
                    shadowplane.position.y = 0.09999;
                    shadowplane.rotation.y = obstacle.rotationY;
                    shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
                    shadowplane.visibility = 0;

                return {
                    name:obstacle.name,
                    lastTrigger:0,
                    cooldown:1000,
                    isGoal:false,
                    points: obstacle.score,
                    renderedObject: object,
                    collisionObject: object,
                    fakeShadows: [shadowplane],
                    position: obstacle.position,
                    zone: obstacle.zone
                }
            } catch (e) {
                console.error(`unable to process ${obstacle.name}`, e);
            }
            return;
        }
    }
];